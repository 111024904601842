<template>
	<v-container fluid fill-height>
		<transition name="slide-fade-transition-css">
			<v-row align="center" justify="center" v-show="Show">
				<v-col cols="12">
					<v-card
						dark
						id="vcard-login"
						:class="CardClass"
						max-width="400"
						:raised="true"
						:elevation="24"
						:loading="LoggingIn"
					>
						<v-card-title>
							<span class="title">Login</span>
						</v-card-title>
						<transition name="flipX">
							<v-form
								v-model="LoginValid"
								ref="form"
								v-show="!LoggedIn"
								:lazy-validation="true"
							>
								<v-expand-transition>
									<v-alert
										v-if="$route.query.alert"
										class="mx-4"
										dismissible
										type="success"
										>You have been logged out</v-alert
									>
								</v-expand-transition>

								<v-expand-transition>
									<div>
										<v-row>
											<v-col cols="1" />
											<v-col cols="9">
												<v-text-field
													v-model="UserName"
													class="full-opacity"
													prepend-icon="mdi-email"
													label="Email"
													:rules="emailRules"
													:error-messages="ErrorMsgs"
													@focus="ErrorMsgs = []"
													@blur="emailOnLeave()"
												/>
											</v-col>
										</v-row>

										<v-row>
											<v-col cols="1" />
											<v-col cols="9">
												<v-text-field
													v-model="Password"
													prepend-icon="mdi-lock-outline"
													:append-icon="
														passwordShow ? 'mdi-eye' : 'mdi-eye-off'
													"
													:rules="[rules_Default.required, rules_Default.min]"
													:type="passwordShow ? 'text' : 'password'"
													:error-messages="ErrorMsgs"
													name="input-10-2"
													label="Password"
													:hint="
														Password.length < 8 ? 'At least 8 characters' : ''
													"
													value="wqfasds"
													@click:append="passwordShow = !passwordShow"
													@focus="ErrorMsgs = []"
													@keydown.enter="Login"
													@blur="passwordOnLeave()"
												/>
												<v-layout row wrap justify-end>
													<v-flex shrink>
														<v-checkbox
															dense
															v-model="Remember"
															label="Remember me"
														/>
													</v-flex>
												</v-layout>
											</v-col>
										</v-row>
									</div>
								</v-expand-transition>
							</v-form>
						</transition>

						<transition name="flipY">
							<v-container fluid v-show="LoggedIn && Animation2Go">
								<v-row>
									<v-col align="center">
										<v-icon size="150" color="success"
											>mdi-check-circle-outline</v-icon
										>
									</v-col>
								</v-row>
							</v-container>
						</transition>

						<v-card-actions v-show="!LoggedIn">
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								class="px-4"
								@click="Login"
								:loading="LoggingIn"
								:disabled="LoggingIn"
								>Login</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</transition>
	</v-container>
</template>

<script>
import axios from "axios";
// eslint-disable-next-line
import lodash from "lodash";
export default {
	data() {
		return {
			UserName: "",
			Password: "",
			Show: false,
			passwordShow: false,
			LoggingIn: false,
			LoginValid: false,
			LoggedIn: false,
			Animation2Go: false,
			Remember: false,
			ErrorMsgs: [],
			CardClass: "mx-auto my-auto card-back",
			DefaultCardClass: "mx-auto my-auto card-back",
			emailRules_Default: [
				(v) => !!v || "E-mail is required",
				(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
			],
			rules_Default: {
				required: (value) => !!value || "Required",
				min: (v) => v.length >= 4 || "Minimum of 8 characters",
			},

			rules: [],
			emailRules: [],
		};
	},
	mounted() {
		setTimeout(() => {
			this.Show = true;
		}, 400);

		if (localStorage.userToken) {
			//TEST THE LOGIN IF IT WORKS NO NEED TO
			//LOGIN AGAIN, SO REDIRECT TO THE CLUB
			//DASHBOARD
			axios.get(`https://api.ticketvm.com/users`).then((data) => {
				console.log(data);
				this.$store.dispatch("update_user_data", data.data);
				axios
					.get(
						`https://api.ticketvm.com/organization/${this.$store.state.Organization.id}/mailbox/my`
					)
					.then(({ data }) => {
						this.$store.dispatch("setMailboxes", data);
					});
				this.GoodLogin();
			});
		}
	},
	computed: {
		serializedusername() {
			return this.UserName.toLowerCase();
		},
	},
	watch: {
		UserName() {
			this.emailRules = [];
		},
		Password() {
			this.rules = [];
		},
	},
	methods: {
		emailOnLeave() {
			this.emailRules = this.emailRules_Default;
		},
		passwordOnLeave() {
			this.rules = this.rules_Default;
		},
		Login() {
			this.emailOnLeave();
			this.passwordOnLeave();
			let valid = this.$refs.form.validate();

			if (!valid || !this.LoginValid) {
				this.BadLogin();
				return;
			}

			this.LoggingIn = true;

			let token_1 = btoa(`${this.serializedusername}:${this.Password}`);
			let token = btoa(`Basic ${token_1}`);

			this.$store.dispatch("update_user_token", token);

			console.log(this.serializedusername, this.Password);
			axios
				.get(`https://api.ticketvm.com/users`)
				.then((data) => {
					console.log(data);
					this.$store.dispatch("update_user_data", data.data);
					axios
						.get(
							`https://api.ticketvm.com/organization/${this.$store.state.Organization.id}/mailbox/my`
						)
						.then(({ data }) => {
							this.$store.dispatch("setMailboxes", data);
						});
					this.GoodLogin();
				})
				.catch((err) => {
					console.log(err, err.response, err.response.body);
					let errstatus = err.response.status;

					if (errstatus == 401 || errstatus == 403) {
						this.BadLogin();
						this.ErrorMsgs.push("Username or password does not match");
					} else {
						console.error(err);
						this.ErrorMsgs.push("There was an unknown error");
					}
				});
		},

		GoodLogin() {
			this.LoggedIn = true;

			setTimeout(() => {
				this.Animation2Go = true;
				setTimeout(() => {
					if (this.Remember) localStorage.Remember = this.UserName;
					else delete localStorage.Remember;

					this.$router.push("/tickets");
				}, 1000);
			}, 1000);
		},

		BadLogin() {
			this.LoggingIn = false;
			this.CardClass += " invalid-shaker";
			setTimeout(() => {
				this.CardClass = this.DefaultCardClass;
			}, 500);
		},
	},
};
</script>

<style scoped>
.bgrnd-overlay {
	backdrop-filter: blur(4px);
}

.card-back {
	backdrop-filter: blur(8px);
	opacity: 0.94;
	transition-property: transform opacity;
}

.headline {
	color: white;
}

.full-opacity {
	opacity: 1;
}

.slide-fade-transition-css-enter-active {
	transition: transform 1s ease;
}

.slide-fade-transition-css-enter,
.slide-fade-transition-css-leave-to {
	transform: translateY(90px);
	opacity: 0;
}

.invalid-shaker {
	outline-color: red;

	animation: bad-shaker 0.5s linear;
}

@keyframes bad-shaker {
	8%,
	41% {
		transform: translateX(-10px);
	}
	25%,
	58% {
		transform: translateX(10px);
	}
	75% {
		transform: translateX(-5px);
	}
	92% {
		transform: translateX(5px);
	}
	0%,
	100% {
		transform: translateX(0);
	}
}
</style>
